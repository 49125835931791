<template>
  <section id="services" class="services-section">
    <div class="container">
      <h2 class="section-title">Our Services</h2>
      <div class="services-list">
        <div v-for="(service, index) in services" :key="service.title" class="service-item">
          <div class="service-content">
            <h3>{{ service.title }}</h3>
            <p>{{ service.description }}</p>
          </div>
          <div v-if="index < services.length - 1" class="separator"></div>
        </div>
      </div>
    </div>
    <!-- <div class="section-separator"></div> -->
  </section>
</template>

<script>
export default {
  name: 'ServicesSection',
  data() {
    return {
      services: [
        {
          icon: 'fas fa-chess fa-2x',
          title: 'Strategic AI Advisory',
          description: 'Cut through the AI hype with clear, actionable strategies that align with your business goals and deliver measurable bottom-line impact.'
        },
        {
          icon: 'fas fa-lightbulb fa-2x',
          title: 'Data-Driven Insights',
          description: 'Transform complex data into clear business insights that drive better decision-making and uncover new opportunities for growth and efficiency.'
        },
        {
          icon: 'fas fa-chalkboard-teacher fa-2x',
          title: 'Executive Training',
          description: 'Equip your leadership team with the practical knowledge to confidently navigate AI and data opportunities, risks, and strategic decisions.'
        },
        {
          icon: 'fas fa-chart-bar fa-2x',
          title: 'Performance Optimization',
          description: 'Identify and eliminate operational inefficiencies using AI and analytics, turning data into a powerful tool for improving business performance.'
        },
        {
          icon: 'fas fa-compass fa-2x',
          title: 'Digital Transformation',
          description: 'Navigate your organization\'s journey into the AI era with a clear roadmap that minimizes risks and maximizes return on investment.'
        },
      ]
    }
  }
}
</script>

<style scoped>
.services-section {
  background-color: #ffffff;
  padding: 80px 0 0; /* Removed bottom padding */
  position: relative; /* Added for absolute positioning of section-separator */
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.section-title {
  text-align: center;
  font-family: 'Optima', sans-serif;
  font-size: 2.5rem;
  margin-bottom: 50px;
  color: #1C2541;
}

.services-list {
  display: flex;
  flex-direction: column;
}

.service-item {
  padding: 30px 40px;
  position: relative;
}

.service-content h3 {
  font-family: 'Optima', sans-serif;
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #1C2541;
}

.service-content p {
  font-size: 1rem;
  line-height: 1.6;
  color: #333;
}

.separator {
  height: 1px;
  background-color: #D4AF37;
  opacity: 0.5;
  margin: 0 40px;
}

.service-item::before {
  content: '';
  position: absolute;
  left: 0;
  top: 30px;
  bottom: 30px;
  width: 3px;
  background-color: #3AAFA9;
}

.section-separator {
  height: 6px;
  background: linear-gradient(to right, #ffffff, #3AAFA9, #ffffff);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

@media (max-width: 768px) {
  .service-item {
    padding: 20px;
  }

  .separator {
    margin: 0 20px;
  }

  .service-item::before {
    top: 20px;
    bottom: 20px;
  }
}
</style>